/*
 * calling function to initiate plugins on DOM ready
*/
function initPlugins() {
  // initiate popover and tooltip
  //console.log('app init');
  //keep this commented for performance reasons... uncomment if you need for your project
  /*
  $('[data-bs-toggle="popover"]').popover();
  $('[data-bs-toggle="tooltip"]').tooltip()
  */
}
jQuery(window).on( "load", function() {
  initPlugins();
});
let items = document.querySelectorAll('.carousel:not(#newCarousel) .carousel-item')

items.forEach((el) => {
  const minPerSlide = 4
  let next = el.nextElementSibling
  for (var i = 1; i < minPerSlide; i++) {
    if (!next) {
      // wrap carousel by using first child
      next = items[0]
    }
    let cloneChild = next.cloneNode(true)
    el.appendChild(cloneChild.children[0])
    next = next.nextElementSibling
  }
})